import React from "react";
import { Button } from "@amzn/awsui-components-react";

import { getHeaderCounterText } from "./table-config";

function Header(props) {
  const counter = getHeaderCounterText(props.items, props.selectedItems);

  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2>
            Notifications
            <span
              id="custom-actions-stripe-counter"
              className="awsui-util-header-counter"
            >
              {` ${counter}`}
            </span>
          </h2>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button
            icon="refresh"
            disabled={props.inProgress}
            onClick={() => props.onRefresh()}
          />
          <Button
            disabled={props.selectedItems.length === 0 || props.inProgress}
            onClick={() => props.onMarkSelectedItemsAsDeleted()}
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Header;
