/**
 * This file contains all of the custom graphQL queries
 * our application uses in order to fetch and structure
 * all of the required data from the appsync api.
 */

// get all notifications for the user
export const listNotificationsForUser = /* GraphQL */ `
  query ListNotificationsForUser($id: ID!) {
    listNotificationUserConnections(filter: { userId: { eq: $id } }) {
      items {
        id
        notification {
          sender
          subject
          message
          createdAt
        }
      }
    }
  }
`;

// get a specific notification for a specific user
export const getNotificationForUser = /* GraphQL */ `
  query ListNotificationUserConnections($userId: ID!, $notificationId: ID!) {
    listNotificationUserConnections(
      filter: { userId: { eq: $userId }, and: { id: { eq: $notificationId } } }
    ) {
      items {
        id
        notification {
          sender
          subject
          message
          createdAt
        }
      }
    }
  }
`;

// retrieve a file by the provided id. this query differs 
// from its autogenerated counterpart in how deeply nested 
// it is in order to get all of the usernames of the 
// listed contributors
export const getFileById = /* GraphQL */ `
  query getFileById($id: ID!) {
    getFile(id: $id) {
      id
      folderId
      name
      location {
        region
        bucket
        key
      }
      status
      createdAt
      updatedAt
      contributors {
        items {
          user {
            id
            username
          }
        }
      }
    }
  }
`;

// retrieves a team's folders and all the files within 
// each of those folders. used to populate the files 
// table view.
export const getTeamWithFoldersAndFiles = /* GraphQL */ `
  query getTeamWithFoldersAndFiles($id: ID!) {
    listTeams(filter: { id: { eq: $id } }) {
      items {
        id
        name
        folders {
          items {
            id
            teamId
            name
            files {
              items {
                id
                folderId
                name
                location {
                  region
                  bucket
                  key
                }
                status
                createdAt
                updatedAt
                contributors {
                  items {
                    user {
                      id
                      username
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
