import React from "react";

function Empty() {
  return (
    <div className="awsui-util-t-c awsui-util-mv-s">
      <b>No notifications</b>
      <p className="awsui-util-mb-xs">No notifications to display.</p>
    </div>
  );
}

export default Empty;
