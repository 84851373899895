import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TablePagination,
  TableSelection,
  TableSorting,
} from "@amzn/awsui-components-react";

import {
  fetchNotificationsForUser,
  deleteNotification,
} from "../../models/Notification";

import {
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS,
  addColumnSortLabels,
  notificationSelectionLabels,
  paginationLabels,
} from "./table-config";

import Header from "./Header";
import Empty from "./Empty";

const columnDefinitions = addColumnSortLabels(
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS
);

function Notifications(props) {
  document.title = "Notifications - DWaaS Pipeline Portal";

  const [isLoading, setIsLoading] = useState(true);
  const [inProgress, setInProgress] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [sortingColumn, setSortingColumn] = useState("createdAt");
  const [sortingDescending, setSortingDescending] = useState(true);

  useEffect(() => {
    fetchData(props.user.id);
  }, [props.user.id]);

  async function fetchData(userId) {
    const notifications = await fetchNotificationsForUser(userId);
    const sorted = notifications.sort(
      (x, y) => new Date(y.createdAt) - new Date(x.createdAt)
    );
    setNotifications(sorted);
    setIsLoading(false);
  }

  async function markSelectedItemsAsDeleted() {
    setInProgress(true);

    try {
      await Promise.all(
        selectedItems.map((notification) => deleteNotification(notification.id))
      );

      setNotifications((notifications) => {
        return notifications.filter(
          (notification) => !selectedItems.includes(notification)
        );
      });

      const message = `${
        selectedItems.length > 1 ? "Notifications" : "Notification"
      } deleted successfully.`;
      props.triggerNotification(message);
      setSelectedItems([]);
    } catch (err) {
      console.error(err);
      props.triggerNotification(
        "An error occurred while deleting one or more of your notifications. Please try again.",
        "error"
      );
    }

    setInProgress(false);
  }

  async function refreshData() {
    setIsLoading(true);
    fetchData(props.user.id);
  }

  function onPaginationChange(event) {
    setPageSize(event.detail.pageSize);
  }

  function onSortingChange(event) {
    setSortingColumn(event.detail.sortingColumn);
    setSortingDescending(event.detail.sortingDescending);
  }

  function onSelectionChange(event) {
    setSelectedItems(event.detail.selectedItems);
  }

  const header = (
    <Header
      items={notifications}
      selectedItems={selectedItems}
      inProgress={inProgress}
      onRefresh={refreshData}
      onMarkSelectedItemsAsDeleted={markSelectedItemsAsDeleted}
    />
  );

  const empty = <Empty />;

  return (
    <Table
      loading={isLoading}
      loadingText="Loading notifications"
      columnDefinitions={columnDefinitions}
      items={notifications}
      stickyHeader={true}
      wrapLines={false}
      header={header}
      empty={empty}
    >
      <TablePagination
        labels={paginationLabels}
        onPaginationChange={onPaginationChange}
        pageSize={pageSize}
      />
      <TableSorting
        sortableColumns={SORTABLE_COLUMNS}
        sortingColumn={sortingColumn}
        onSortingChange={onSortingChange}
        sortingDescending={sortingDescending}
      />
      <TableSelection
        selectedItems={selectedItems}
        labels={notificationSelectionLabels}
        onSelectionChange={onSelectionChange}
      />
    </Table>
  );
}

export default withRouter(Notifications);
