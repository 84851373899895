/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        nextToken
      }
      folders {
        items {
          id
          teamId
          name
        }
        nextToken
      }
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        nextToken
      }
      folders {
        items {
          id
          teamId
          name
        }
        nextToken
      }
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      users {
        items {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        nextToken
      }
      folders {
        items {
          id
          teamId
          name
        }
        nextToken
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      sub
      teamId
      username
      first_name
      last_name
      email
      files {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
      notifications {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      sub
      teamId
      username
      first_name
      last_name
      email
      files {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
      notifications {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      sub
      teamId
      username
      first_name
      last_name
      email
      files {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
      notifications {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const createFolder = /* GraphQL */ `
  mutation CreateFolder(
    $input: CreateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    createFolder(input: $input, condition: $condition) {
      id
      teamId
      name
      files {
        items {
          id
          folderId
          name
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateFolder = /* GraphQL */ `
  mutation UpdateFolder(
    $input: UpdateFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    updateFolder(input: $input, condition: $condition) {
      id
      teamId
      name
      files {
        items {
          id
          folderId
          name
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteFolder = /* GraphQL */ `
  mutation DeleteFolder(
    $input: DeleteFolderInput!
    $condition: ModelFolderConditionInput
  ) {
    deleteFolder(input: $input, condition: $condition) {
      id
      teamId
      name
      files {
        items {
          id
          folderId
          name
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      id
      folderId
      name
      location {
        region
        bucket
        key
      }
      status
      createdAt
      updatedAt
      contributors {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
    }
  }
`;
export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      id
      folderId
      name
      location {
        region
        bucket
        key
      }
      status
      createdAt
      updatedAt
      contributors {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
    }
  }
`;
export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      id
      folderId
      name
      location {
        region
        bucket
        key
      }
      status
      createdAt
      updatedAt
      contributors {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      sender
      subject
      message
      createdAt
      recipients {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      sender
      subject
      message
      createdAt
      recipients {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      sender
      subject
      message
      createdAt
      recipients {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const createFileUserConnection = /* GraphQL */ `
  mutation CreateFileUserConnection(
    $input: CreateFileUserConnectionInput!
    $condition: ModelFileUserConnectionConditionInput
  ) {
    createFileUserConnection(input: $input, condition: $condition) {
      id
      userId
      fileId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      file {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
    }
  }
`;
export const updateFileUserConnection = /* GraphQL */ `
  mutation UpdateFileUserConnection(
    $input: UpdateFileUserConnectionInput!
    $condition: ModelFileUserConnectionConditionInput
  ) {
    updateFileUserConnection(input: $input, condition: $condition) {
      id
      userId
      fileId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      file {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
    }
  }
`;
export const deleteFileUserConnection = /* GraphQL */ `
  mutation DeleteFileUserConnection(
    $input: DeleteFileUserConnectionInput!
    $condition: ModelFileUserConnectionConditionInput
  ) {
    deleteFileUserConnection(input: $input, condition: $condition) {
      id
      userId
      fileId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      file {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
    }
  }
`;
export const createNotificationUserConnection = /* GraphQL */ `
  mutation CreateNotificationUserConnection(
    $input: CreateNotificationUserConnectionInput!
    $condition: ModelNotificationUserConnectionConditionInput
  ) {
    createNotificationUserConnection(input: $input, condition: $condition) {
      id
      userId
      notificationId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      notification {
        id
        sender
        subject
        message
        createdAt
        recipients {
          nextToken
        }
      }
    }
  }
`;
export const updateNotificationUserConnection = /* GraphQL */ `
  mutation UpdateNotificationUserConnection(
    $input: UpdateNotificationUserConnectionInput!
    $condition: ModelNotificationUserConnectionConditionInput
  ) {
    updateNotificationUserConnection(input: $input, condition: $condition) {
      id
      userId
      notificationId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      notification {
        id
        sender
        subject
        message
        createdAt
        recipients {
          nextToken
        }
      }
    }
  }
`;
export const deleteNotificationUserConnection = /* GraphQL */ `
  mutation DeleteNotificationUserConnection(
    $input: DeleteNotificationUserConnectionInput!
    $condition: ModelNotificationUserConnectionConditionInput
  ) {
    deleteNotificationUserConnection(input: $input, condition: $condition) {
      id
      userId
      notificationId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      notification {
        id
        sender
        subject
        message
        createdAt
        recipients {
          nextToken
        }
      }
    }
  }
`;
