import React from "react";
import { Icon, ColumnLayout } from "@amzn/awsui-components-react";

import Link from "../../components/Link";

import "./Home.css";

function Header() {
  return (
    <div className="home__header">
      <div className="col-xxs-12">
        <div className="awsui-row">
          <div className="home__category col-xxs-10 offset-xxs-1 col-l-8 offset-l-2">
            DWaaS
          </div>
        </div>
        <div className="awsui-row">
          <div className="home__header-title col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
            <div className="awsui-text-large">
              <div>
                <strong>DWaaS</strong>
              </div>
              <div>Pipeline Portal</div>
            </div>
            <p>
              DWaaS Pipeline Portal is a file management service that enables
              teams to easily add, remove, and deploy schemas in to the ETL
              Platform.
            </p>
          </div>
          <div className="col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
            <div className="awsui-util-container awsui-util-mb-n">
              <ColumnLayout>
                <div data-awsui-column-layout-root={true}>
                  <p>
                    Get started with DWaaS Pipeline Portal by creating a file
                    and triggering a deployment.
                  </p>
                  <Link variant="primary" text="Create file" to="/files/new" />
                </div>
              </ColumnLayout>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LeftColumn() {
  return (
    <div className="col-xxs-10 offset-xxs-1 col-s-6 col-l-5 offset-l-2 col-xl-6">
      <h1>How it works</h1>
      <div className="awsui-util-container">
        <div>
          <p>
            Data Warehouse as a Service (DWaaS) is an AppSec approved service
            that provides customers with a fully managed Redshift cluster
            connected to the Galaxi data lake with the ability to easily ingest
            data from secondary (e.g. Hammerstone, Hoot, Cradle) and tertiary
            (CSV, TXT) data sources. This service also comes with with
            integrated fully self-serve ETL capabilities powered by AWS Glue.
            The end result is a Redshift cluster that the requesting team has
            full control over, we provide the infrastructure and setup, all you
            have to do is use it.
          </p>
        </div>
      </div>
    </div>
  );
}

function RightColumn() {
  return (
    <div className="home__sidebar col-xxs-10 offset-xxs-1 col-s-4 offset-s-0 col-l-3 col-xl-2">
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>
            Getting Started <Icon name="external" />
          </h2>
        </div>
        <div>
          <div className="table-row">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://w.amazon.com/bin/view/Datawarehouse_as_a_Service_%28DWaaS%29/#HDWaaSPipelinePortal"
            >
              What is DWaaS Pipeline Portal?
            </a>
          </div>
          <div className="table-row">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://w.amazon.com/bin/view/Datawarehouse_as_a_Service_%28DWaaS%29/#HGettingstartedwithDWaaSPipelinePortal"
            >
              Getting started with DWaaS Pipeline Portal
            </a>
          </div>
          <div className="table-row">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://w.amazon.com/bin/view/Datawarehouse_as_a_Service_%28DWaaS%29/#HWorkingwithDWaaSPipelinePortal"
            >
              Working with DWaaS Pipeline Portal
            </a>
          </div>
        </div>
      </div>

      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>
            More Resources <Icon name="external" />
          </h2>
        </div>
        <div>
          <div className="table-row">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://w.amazon.com/bin/view/Datawarehouse_as_a_Service_(DWaaS)"
            >
              Documentation
            </a>
          </div>
          <div className="table-row">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://issues.amazon.com/issues/create?template=cf7b86a4-e4bd-4724-b9f5-e0faefb54626"
            >
              Submit an on-boarding request
            </a>
          </div>
          <div className="table-row">
            <a href="mailto:ssd-bia-solutions <ssd-bia-solutions@amazon.com>">
              Contact us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

function Home(props) {
  document.title = "DWaaS Pipeline Portal";

  return (
    <div className="Home home-page awsui-grid awsui-util-p-s">
      <div className="awsui-row">
        <Header />
      </div>
      <div className="awsui-row awsui-util-pt-xxl">
        <LeftColumn />
        <RightColumn />
      </div>
    </div>
  );
}

export default Home;
