import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react";

import {
  fetchFileById,
  fetchDownloadLink,
  deleteFileForUser,
} from "../../models/File";

import { fetchTeamById } from "../../models/Team";

import Loader from "../../components/Loader";
import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";
import { getDateAndTime, toSentenceCase } from "../../utils";

import "./FileDetails.css";

function FileNotFound(props) {
  return (
    <div className="FileNotFound">
      <h3>No file by that id was found.</h3>
      <p>
        You may have typed the id incorrectly or you may have used an outdated
        link.
      </p>
    </div>
  );
}

function File(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [downloadLink, setDownloadLink] = useState(null);

  useEffect(() => {
    async function fetchLink(file) {
      const link = await fetchDownloadLink(file);
      setDownloadLink(link);
    }

    fetchLink(props.file);
  }, [props.file]);

  function dismissModal(ev) {
    ev.preventDefault();
    setShowModal(false);
  }

  async function removeFile() {
    setIsLoading(true);
    await deleteFileForUser(props.file, props.user.id);
    setIsLoading(false);
    setShowModal(false);
    props.triggerNotification("File deleted successfully.");
    props.history.push("/files");
  }

  return (
    <div>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>{props.file.name}</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                type="button"
                target="_blank"
                rel="noopener noreferrer"
                href={downloadLink}
                download={props.file.name}
                title="Download file"
              >
                Download
              </Button>
              <Button type="button" onClick={() => setShowModal(true)}>
                Delete
              </Button>
            </div>
          </div>
        </div>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">ID</div>
            <div>{props.file.id}</div>
          </div>
          <div>
            <div className="awsui-util-label">Status</div>
            <div>{toSentenceCase(props.file.status)}</div>
          </div>
          <div>
            <div className="awsui-util-label">Location</div>
            <div>{props.file.location.key.split("/").slice(1).join("/")}</div>
          </div>
          <div>
            <div className="awsui-util-label">Last modified</div>
            <div>{getDateAndTime(props.file.updatedAt)}</div>
          </div>
          <div>
            <div className="awsui-util-label">Contributors</div>
            <div>
              {props.file.contributors.map((user, index) =>
                index === 0 ? `${user.username}` : `, ${user.username}`
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmDeleteModal
        isLoading={isLoading}
        visible={showModal}
        fileName={props.file && props.file.name}
        onDismiss={dismissModal}
        onConfirm={removeFile}
      />
    </div>
  );
}

function FileDetails(props) {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    async function fetchData(teamId, fileId) {
      const team = await fetchTeamById(teamId);
      const file = await fetchFileById(fileId);

      if (file === null || !team.hasFolder(file.folderId)) {
        setNotFound(true);
      } else {
        setFile(file);
        document.title = `${file.name} - DWaaS Pipeline Portal`;
      }

      setIsLoading(false);
    }

    const teamId = props.user.teamId;
    const fileId = props.match.params.id;
    fetchData(teamId, fileId);
  }, [props.user.teamId, props.match.params.id]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : notFound ? (
        <FileNotFound {...props} />
      ) : (
        <File file={file} {...props} />
      )}
    </div>
  );
}

export default withRouter(FileDetails);
