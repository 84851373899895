/**
 * This file contains all of the custom graphQL subscriptions
 * our application uses in order to fetch and display notification
 * events
 */

export const onCreateNotification = /* GraphQL */ `
  subscription onCreateNotification($userId: ID!) {
    onNotificationCreated(userId: $userId) {
      id
      notification {
        sender
        subject
        message
        createdAt
      }
    }
  }
`;

export const onDeleteNotification = /* GraphQL */ `
  subscription onDeleteNotification($userId: ID!) {
    onNotificationDeleted(userId: $userId) {
      id
      notification {
        sender
        subject
        message
        createdAt
      }
    }
  }
`;
