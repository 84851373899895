import React from "react";
import TimeAgo from "timeago-react";

export function getDateAndTime(createdAt) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };
  const dateString = new Date(createdAt).toLocaleDateString(
    undefined,
    dateOptions
  );

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
  };
  const timeString = new Date(createdAt).toLocaleTimeString(
    undefined,
    timeOptions
  );

  const timeAgo = <TimeAgo datetime={createdAt} />;

  return (
    <div>
      <span>{`${dateString}, ${timeString} `}</span>
      <span>
        {"("}
        {timeAgo}
        {")"}
      </span>
    </div>
  );
}

export function toSentenceCase(status) {
  return [status.slice(0, 1).toUpperCase(), status.slice(1).toLowerCase()].join(
    ""
  );
}
