import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  TablePagination,
  TablePropertyFiltering,
  TableSelection,
  TableSorting,
} from "@amzn/awsui-components-react";

import Header from "./Header";
import NoMatch from "./NoMatch";
import Empty from "./Empty";

import {
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS,
  PROPERTY_FILTERING_OPTIONS,
  PROPERTY_FILTERING_I18N_CONSTANTS,
  addColumnSortLabels,
  removeTokenButtonLabel,
  paginationLabels,
  selectionLabels,
  getFilterCounterText,
} from "./table-config";

import { fetchTeamWithFolderAndFiles } from "../../models/Team";
import { deleteFileForUser } from "../../models/File";

import ConfirmDeleteModal from "../../components/ConfirmDeleteModal";

const columnDefinitions = addColumnSortLabels(
  COLUMN_DEFINITIONS,
  SORTABLE_COLUMNS
);

function Files(props) {
  document.title = "Files - DWaaS Pipeline Portal";

  const [team, setTeam] = useState(null);

  // Table properties
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteringText, setFilteringText] = useState("");
  const [filteringOptions, setFilteringOptions] = useState([]);
  const [filteringTokens, setFilteringTokens] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [sortingColumn, setSortingColumn] = useState("updatedAt");
  const [sortingDescending, setSortingDescending] = useState(true);

  // Modal properties
  const [showModal, setShowModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  useEffect(() => {
    async function fetchTeamData(teamId) {
      const team = await fetchTeamWithFolderAndFiles(teamId);
      if (team === null) {
        console.error(`No team found by id "${teamId}"`);
        props.history.push("/error");
        return;
      }

      setTeam(team);
      setItems(team.files);

      const filteringOptions = PROPERTY_FILTERING_OPTIONS.map(
        (filteringOption) => {
          filteringOption.values = team.files.map(
            (file) => file[filteringOption.propertyKey]
          );
          return filteringOption;
        }
      );
      setFilteringOptions(filteringOptions);
      setIsLoading(false);
    }

    fetchTeamData(props.user.teamId);
  }, [props.user.teamId, props.history]);

  async function refreshData(teamId) {
    const team = await fetchTeamWithFolderAndFiles(teamId);
    setTeam(team);
    setItems(team.files);

    const filteringOptions = PROPERTY_FILTERING_OPTIONS.map(
      (filteringOption) => {
        filteringOption.values = team.files.map(
          (file) => file[filteringOption.propertyKey]
        );
        return filteringOption;
      }
    );
    setFilteringOptions(filteringOptions);
    setIsLoading(false);
  }

  function onPropertyFilteringChange(event) {
    setFilteringTokens(event.detail.tokens);
  }

  function onPaginationChange(event) {
    setPageSize(event.detail.pageSize);
  }

  function onSortingChange(event) {
    setSortingColumn(event.detail.sortingColumn);
    setSortingDescending(event.detail.sortingDescending);
  }

  function onSelectionChange(event) {
    setSelectedItems(event.detail.selectedItems);
  }

  async function refreshItems() {
    setIsLoading(true);
    await refreshData(props.user.teamId);
  }

  async function deleteSelectedItem() {
    setDeleteInProgress(true);

    try {
      const file = selectedItems.slice().pop();
      await deleteFileForUser(file, props.user.id);

      setDeleteInProgress(false);
      setShowModal(false);
      props.triggerNotification("File deleted successfully.");

      setItems(() => {
        team.deleteFile(file);
        return team.files;
      });
    } catch (err) {
      console.error(err);
      props.triggerNotification(
        "An error occurred while deleting the file. Please try again.",
        "error"
      );
    }
  }

  function dismissModal(ev) {
    ev.preventDefault();
    setShowModal(false);
  }

  function clearFilter() {
    setFilteringText("");
    setFilteringTokens([]);
  }

  const header = (
    <Header
      items={items}
      teamName={(team && team.name) || ""}
      selectedItems={selectedItems}
      onRefresh={refreshItems}
      onDelete={() => setShowModal(true)}
    />
  );
  const noMatch = <NoMatch onClearFilter={clearFilter} />;
  const empty = <Empty />;

  return (
    <div>
      <Table
        loading={isLoading}
        loadingText="Loading files"
        columnDefinitions={columnDefinitions}
        items={items}
        stickyHeader={true}
        wrapLines={false}
        header={header}
        noMatch={noMatch}
        empty={empty}
      >
        <TablePropertyFiltering
          {...PROPERTY_FILTERING_I18N_CONSTANTS}
          filteringText={filteringText}
          filteringOptions={filteringOptions}
          tokens={filteringTokens}
          onPropertyFilteringChange={onPropertyFilteringChange}
          removeTokenButtonLabel={removeTokenButtonLabel}
          allowFreeTextFiltering={true}
          filteringCountTextFunction={getFilterCounterText}
        />
        <TablePagination
          labels={paginationLabels}
          onPaginationChange={onPaginationChange}
          pageSize={pageSize}
        />
        <TableSorting
          sortableColumns={SORTABLE_COLUMNS}
          sortingColumn={sortingColumn}
          onSortingChange={onSortingChange}
          sortingDescending={sortingDescending}
        />
        <TableSelection
          selectionType="single"
          labels={selectionLabels}
          selectedItems={selectedItems}
          onSelectionChange={onSelectionChange}
        />
      </Table>

      <ConfirmDeleteModal
        isLoading={deleteInProgress}
        visible={showModal}
        fileName={selectedItems.length > 0 ? selectedItems[0].name : ""}
        onDismiss={dismissModal}
        onConfirm={deleteSelectedItem}
      />
    </div>
  );
}

export default withRouter(Files);
