import { API, graphqlOperation, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { createFile, createFileUserConnection } from "../graphql/mutations";
import { getFolder } from "../graphql/queries";

import { getFileById } from "./customQueries";
import { AWS_REGION, S3_BUCKET_ID } from "../constants";

export class File {
  constructor(
    id,
    folderId,
    folderName,
    name,
    location,
    status,
    createdAt,
    updatedAt,
    contributors
  ) {
    this.id = id;
    this.folderId = folderId;
    this.folderName = folderName;
    this.name = name;
    this.location = location;
    this.status = status;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this._contributors = contributors;
  }

  get contributors() {
    return this._contributors.items.map((item) => item.user);
  }
}

export async function fetchDownloadLink(file) {
  // Storage.get will automatically prepend a 'public/' to the filename so
  // we'll need to remove the prefix from the name before downloading
  // See issue: https://github.com/aws-amplify/amplify-js/issues/332
  const filename = file.location.key.split("/").slice(1).join("/");
  return await Storage.get(filename);
}

export async function fetchFileById(id) {
  const response = await API.graphql(graphqlOperation(getFileById, { id }));
  const file = response.data.getFile;
  const folderResponse = await API.graphql(
    graphqlOperation(getFolder, { id: file.folderId })
  );
  const folder = folderResponse.data.getFolder;

  return new File(
    file.id,
    file.folderId,
    folder.name,
    file.name,
    file.location,
    file.status,
    file.createdAt,
    file.updatedAt,
    file.contributors
  );
}

async function uploadFileToS3(fileId, filepath, filePtr) {
  return await Storage.put(filepath, filePtr, {
    // NOTE: This tagging is important. This tag is how the file is retrieved
    // in our s3 lambda to trigger notifications etc.
    tagging: `id=${fileId}`,
  });
}

// link a file to a user in order to list them as a contributor
export async function linkFileAndUser(userId, fileId) {
  const id = uuidv4();
  const input = {
    id,
    fileId,
    userId,
  };

  return await API.graphql(
    graphqlOperation(createFileUserConnection, { input })
  );
}

export async function createNewFile(
  userId,
  folderId,
  folderName,
  fileName,
  filePtr
) {
  const fileId = uuidv4();
  const filepath = `${folderName}/${fileName}/${filePtr.name}`;
  const upload = await uploadFileToS3(fileId, filepath, filePtr);

  const name = filePtr.name;
  const location = {
    region: AWS_REGION,
    bucket: S3_BUCKET_ID,
    // amplify's storage library automatically prepends a 'public/'
    // to all uploads unless configured otherwise (which we have not).
    key: `public/${upload.key}`,
  };
  const status = "uploaded";
  const createdAt = new Date().toISOString();
  const updatedAt = createdAt;

  const input = {
    id: fileId,
    folderId,
    name,
    location,
    status,
    createdAt,
    updatedAt,
  };

  await API.graphql(graphqlOperation(createFile, { input }));
  await linkFileAndUser(userId, fileId);
}

async function deleteFileFromS3(file) {
  // Storage.remove will automatically prepend a 'public/' to the filename
  // so we'll need to remove the prefix from the name before deleting
  // See issue: https://github.com/aws-amplify/amplify-js/issues/332
  const filename = file.location.key.split("/").slice(1).join("/");
  return await Storage.remove(filename);
}

export async function deleteFileForUser(file, userId) {
  // if user is not already on the list of contributors, add them
  if (file.contributors.indexOf((user) => user.id === userId) < 0) {
    await linkFileAndUser(file.id, userId);
  }

  // Note: The graphql mutation has purposefully been left out.
  // That part is delegated to the s3 lambda in order for it to
  // generate a notification for all the listed contributors.
  await deleteFileFromS3(file);
}
