import React from "react";
import { Button, Icon } from "@amzn/awsui-components-react";

import "./SignInError.css";

function Error() {
  return (
    <div className="SignInError">
      <Icon size="large" variant="warning" name="status-warning" />
      <h1>A fatal error has occurred</h1>
      <p>There was a problem signing you in.</p>

      <Button type="button" variant="link" href="/">
        Try again
      </Button>

      <Button
        type="button"
        variant="link"
        href="mailto:ssd-bia-solutions <ssd-bia-solutions@amazon.com>"
      >
        Contact us
      </Button>
    </div>
  );
}

export default Error;
