import React from "react";
import { Link as RouterLink } from "react-router-dom";

// standard hrefs on the polaris Button components cause 
// the page to be fully reloaded which includes the auth
// check etc. to avoid that, we'll use the Link component
// from react router and use polaris class names to make
// them look just like a Button.
function Link({ variant, to, text }) {
  return (
    <awsui-button initialized="true">
      <RouterLink
        className={`awsui-button awsui-button-variant-${
          variant || "normal"
        } awsui-hover-child-icons`}
        to={to}
      >
        <span awsui-button-region="text">{text}</span>
      </RouterLink>
    </awsui-button>
  );
}

export default Link;
