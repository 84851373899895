import React, { useEffect, useState } from "react";
import {
  Button,
  ExpandableSection,
  Form,
  FormField,
  FormSection,
  Icon,
  Tiles,
} from "@amzn/awsui-components-react";
import { withRouter } from "react-router-dom";

import { fetchTeamWithFolderAndFiles } from "../../models/Team";
import { createNewFile, deleteFileForUser } from "../../models/File";

import { getDateAndTime } from "../../utils";
import Loader from "../../components/Loader";
import ConfirmOverwriteModal from "../../components/ConfirmOverwriteModal";

import "./NewFile.css";

const FILE_TYPES = ["SQL File", "Flat File"];
const FLAT_FILE_TYPES = ["csv", "txt", "snappy.parquet"];
const SQL_FILE_TYPES = ["sql"];

function NewFileForm(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedType, setSelectedType] = useState(FILE_TYPES[0]);
  // The available folders are based on the selected file types.
  // "extended" folders map to flat file types.
  const initialFolder = props.team.folders
    .filter((folder) => !folder.name.includes("extended"))
    .pop();
  const [selectedFolder, setSelectedFolder] = useState(initialFolder);

  const [file, setFile] = useState(null);
  const [hasFileError, setHasFileError] = useState(null);
  const [optionalFile, setOptionalFile] = useState(null);
  const [hasOptionalFileError, setHasOptionalFileError] = useState(null);
  const [acceptedFileTypes, setAcceptedFileTypes] = useState(SQL_FILE_TYPES);

  // Confirm modal properties
  const [showModal, setShowModal] = useState(false);
  const [matchingFile, setMatchingFile] = useState(undefined);

  let fileInputRef; // reference to the hidden file input
  let optionalFileInputRef; // reference to the hidden optional file input

  // There are different folders based on the selected file type.
  // When the file type changes, we have to reset all of the
  // rest of the form options.
  function onChangeType(event) {
    const type = FILE_TYPES.find((type) => type === event.detail.value);
    setSelectedType(type);

    let folder;
    if (type.includes("SQL")) {
      folder = props.team.folders
        .filter((folder) => !folder.name.includes("extended"))
        .pop();
    } else {
      folder = props.team.folders
        .filter((folder) => folder.name.includes("extended"))
        .pop();
    }
    setSelectedFolder(folder);

    setAcceptedFileTypes(
      type.includes("SQL") ? SQL_FILE_TYPES : FLAT_FILE_TYPES
    );
    setFile(null);
    setHasFileError(null);
    setOptionalFile(null);
    setHasOptionalFileError(null);
  }

  function onChangeFolder(event) {
    const folder = props.team.folders.find(
      (folder) => folder.name === event.detail.value
    );
    setSelectedFolder(folder);
  }

  function triggerFileInputClick(ev) {
    ev.preventDefault();
    fileInputRef.click();
    return false;
  }

  function validateFileExtension(filePtr) {
    if (acceptedFileTypes === FLAT_FILE_TYPES) {
      if (filePtr.name.split(".").slice(-2).join(".") === "snappy.parquet") {
        return true;
      }
    }

    const extension = filePtr.name.split(".").pop();
    return acceptedFileTypes.includes(extension);
  }

  function onFileChange(event) {
    const filePtr = event.target.files[0];
    setFile(filePtr);

    if (!validateFileExtension(filePtr)) {
      const errorMessage = "File type is invalid.";
      setHasFileError(errorMessage);
    } else if (filePtr.name.includes("-") || filePtr.name.includes(" ")) {
      setHasFileError("File names cannot contain spaces or dashes.");
    } else {
      setHasFileError(null);
    }
  }

  function triggerOptionalFileInputClick(ev) {
    ev.preventDefault();
    optionalFileInputRef.click();
    return false;
  }

  function onOptionalFileChange(event) {
    const filePtr = event.target.files[0];
    setOptionalFile(filePtr);

    if (filePtr.name.split(".").pop() !== "json") {
      setHasOptionalFileError("File type is invalid.");
    } else if (filePtr.name.includes("-") || filePtr.name.includes(" ")) {
      setHasOptionalFileError("File names cannot contain spaces or dashes.");
    } else {
      setHasOptionalFileError(null);
    }
  }

  function validate() {
    if (file === null) {
      setHasFileError("This field is required.");
      return false;
    }

    if (selectedType.includes("SQL")) {
      return file && hasFileError === null && hasOptionalFileError === null;
    }

    return file && hasFileError === null;
  }

  async function createFiles() {
    setIsLoading(true);

    const userId = props.user.id;
    const folderId = selectedFolder.id;
    const folderName = selectedFolder.name;
    const baseFileName = file.name.includes("snappy.parquet")
      ? file.name.split(".").slice(0, -2).join()
      : file.name.split(".").slice(0, -1).join();

    try {
      await createNewFile(userId, folderId, folderName, baseFileName, file);

      if (selectedType.includes("SQL") && optionalFile !== null) {
        await createNewFile(
          userId,
          folderId,
          folderName,
          baseFileName,
          optionalFile
        );
      }

      props.triggerNotification(
        `${optionalFile ? "Files" : "File"} uploaded successfully.`
      );
    } catch (err) {
      console.error(err);
      props.triggerNotification(
        "An error occurred while uploading your file. Please try again.",
        "error"
      );
    }

    setIsLoading(false);
    props.history.push("/files");
  }

  // Delete the existing file and then create a new one.
  async function overwriteFile() {
    setIsLoading(true);

    try {
      const matchingFile = props.team.files
        .filter((item) => item.folderId === selectedFolder.id)
        .find((item) => item.name === file.name);

      await deleteFileForUser(matchingFile, props.user.id);

      // if an optional file has been included, we'll need to peform
      // a check for an existing match of it as well
      if (optionalFile) {
        const matchingOptionalFile = props.team.files
          .filter((item) => item.folderId === selectedFolder.id)
          .find((item) => item.name === optionalFile.name);

        if (matchingOptionalFile) {
          await deleteFileForUser(matchingOptionalFile, props.user.id);
        }
      }

      await createFiles();
    } catch (err) {
      console.error(err);
      props.triggerNotification(
        "An error occurred while updating your file. Please try again.",
        "error"
      );
    }
  }

  async function onSubmit(event) {
    event.preventDefault();

    const isValid = validate();
    if (!isValid) {
      return;
    }

    // check if there's already a file matching the provided
    // file's name.
    const match = props.team.files
      .filter((item) => item.folderId === selectedFolder.id)
      .find((item) => item.name === file.name);

    // if there is a match, prompt the user to confirm
    // overwriting the existing one.
    if (match) {
      setMatchingFile(match);
      setShowModal(true);
    } else {
      await createFiles();
    }
  }

  // form action buttons: Cancel and Submit
  const actions = (
    <div>
      <Button
        type="button"
        variant="link"
        disabled={isLoading}
        onClick={() => props.history.push("/files")}
      >
        Cancel
      </Button>
      <Button
        type="submit"
        variant="primary"
        loading={isLoading}
        disabled={isLoading}
      >
        Submit
      </Button>
    </div>
  );

  // input for the file types form tiles
  const types = FILE_TYPES.map((type) => ({
    label: type,
    value: type,
  }));

  // input for the folders form tiles
  const folders = props.team.folders
    .filter((folder) =>
      selectedType.includes("SQL")
        ? !folder.name.includes("extended")
        : folder.name.includes("extended")
    )
    .map((folder) => ({
      label: folder.name,
      value: folder.id,
    }));

  // Expandable footer section for the SQL file type.
  // Provides an option for the user to include a JSON
  // file to upload alongside their sql.
  const footer = !selectedType.includes("SQL") ? null : (
    <ExpandableSection header="Additional settings" variant="borderless">
      <FormField
        description="Include a JSON file with any partition(s) for the SQL file (optional)"
        hintText="Accepted file type is .json"
        errorText={hasOptionalFileError}
      >
        <input
          style={{ display: "none" }}
          type="file"
          id="optionalFile"
          name="optionalFile"
          ref={(input) => (optionalFileInputRef = input)}
          accept=".json"
          onChange={onOptionalFileChange}
        />
        <Button
          icon="upload"
          disabled={isLoading}
          onClick={triggerOptionalFileInputClick}
        >
          Upload file
        </Button>
      </FormField>
      {optionalFile && (
        <div className="file-details">
          <span
            className={`awsui-util-status-${
              hasOptionalFileError ? "negative" : "positive"
            }`}
          >
            <Icon
              name={`status-${hasOptionalFileError ? "negative" : "positive"}`}
            />
          </span>
          <div>
            <div className="awsui-form-field-label">{optionalFile.name}</div>
            <div className="awsui-form-field-description">{`${optionalFile.size} bytes`}</div>
            <div className="awsui-form-field-description">
              {getDateAndTime(new Date(optionalFile.lastModified))}
            </div>
          </div>
        </div>
      )}
    </ExpandableSection>
  );

  return (
    <form noValidate onSubmit={onSubmit}>
      <Form header="Create file" actions={actions}>
        <FormSection
          header="Choose a file type"
          description="Select whether you'll upload a Flat file or a SQL file."
        >
          <Tiles items={types} value={selectedType} onChange={onChangeType} />
        </FormSection>

        <FormSection
          header="Choose a folder"
          description="Select a folder to upload your file to."
        >
          <Tiles
            items={folders}
            value={selectedFolder.id}
            onChange={onChangeFolder}
          />
        </FormSection>

        <FormSection
          header="Choose a file"
          description="Choose a file to upload."
          footer={footer}
        >
          <FormField
            hintText={`Accepted file ${
              selectedType.includes("SQL") ? "type is" : "types are"
            } ${acceptedFileTypes.map((type) => `.${type}`).join(", ")}`}
            errorText={hasFileError}
          >
            <input
              style={{ display: "none" }}
              type="file"
              id="file"
              name="file"
              ref={(input) => (fileInputRef = input)}
              accept={`${acceptedFileTypes
                .map((type) => `.${type}`)
                .join(", ")}`}
              onChange={onFileChange}
            />
            <Button
              icon="upload"
              disabled={isLoading}
              onClick={triggerFileInputClick}
            >
              Upload file
            </Button>
          </FormField>
          {file && (
            <div className="file-details">
              <span
                className={`awsui-util-status-${
                  hasFileError ? "negative" : "positive"
                }`}
              >
                <Icon
                  name={`status-${hasFileError ? "negative" : "positive"}`}
                />
              </span>
              <div>
                <div className="awsui-form-field-label">{file.name}</div>
                <div className="awsui-form-field-description">{`${file.size} bytes`}</div>
                <div className="awsui-form-field-description">
                  {getDateAndTime(new Date(file.lastModified))}
                </div>
              </div>
            </div>
          )}
        </FormSection>
      </Form>

      <ConfirmOverwriteModal
        visible={showModal}
        isLoading={isLoading}
        fileName={matchingFile && matchingFile.name}
        onDismiss={() => setShowModal(false)}
        onConfirm={overwriteFile}
      />
    </form>
  );
}

function NewFile(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [team, setTeam] = useState(undefined);

  useEffect(() => {
    async function fetchData(teamId) {
      const team = await fetchTeamWithFolderAndFiles(teamId);
      setTeam(team);
      setIsLoading(false);

      document.title = "Create file - DWaaS Pipeline Portal";
    }

    fetchData(props.user.teamId);
  }, [props.user.teamId]);

  return isLoading ? <Loader /> : <NewFileForm team={team} {...props} />;
}

export default withRouter(NewFile);
