import { API, graphqlOperation } from "aws-amplify";
import { userByUsername } from "../graphql/queries";

export class User {
  constructor(id, sub, teamId, username, first_name, last_name, email) {
    this.id = id;
    this.sub = sub;
    this.teamId = teamId;
    this.username = username;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
  }
}

export async function fetchUserByUsername(username) {
  const response = await API.graphql(
    graphqlOperation(userByUsername, { username })
  );
  const user = response.data.userByUsername.items.pop();

  return new User(
    user.id,
    user.sub,
    user.teamId,
    user.username,
    user.first_name,
    user.last_name,
    user.email
  );
}
