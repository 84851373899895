import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import aws_exports from "./aws-exports";
import App from "./App";

import "@amzn/awsui-components-react/index.min.css";

Amplify.configure(aws_exports);

// The Cognito User Pool and Identity Pool were created manually
// outside of amplify's CLI. I could not figure out in time, how to
// reliably configure the resulting cloudformation templates to work
// with amazon's SSO service, federate, before time ran out. At the
// time of writing, the three services supported by the CLI are
// Amazon, Facebook, and Google.Since these were created outside of
// amplify, we have to tell it where to find them by way of
// configuration.
Auth.configure({
  identityPoolId: "us-east-1:4c1e2c9c-1454-46c2-a4fa-fd9e9bb2a276",
  region: "us-east-1",
  userPoolId: "us-east-1_rTnQnIcQj",
  userPoolWebClientId: "794603bcdm6jvp00svnb2mgd7g",
  oauth: {
    domain: "dwaaspipelineportal.auth.us-east-1.amazoncognito.com",
    scope: ["email", "profile", "openid"],
    redirectSignIn: `${window.location.origin}/`,
    redirectSignOut: `${window.location.origin}/`,
    responseType: "code",
  },
});

ReactDOM.render(
  <div className="awsui">
    <Router>
      <App />
    </Router>
  </div>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
