import React from "react";
import { Spinner } from "@amzn/awsui-components-react";

import "./Loader.css";

function Loader() {
  return (
    <div className="Loader">
      <Spinner size="large" />
    </div>
  );
}

export default Loader;
