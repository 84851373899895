import React from "react";
import "./NotFound.css";

function NotFound() {
  return (
    <div className="NotFound">
      <h3>The page you tried was not found.</h3>
      <p>
        You may have typed the address incorrectly or you may have used an
        outdated link.
      </p>
    </div>
  );
}

export default NotFound;
