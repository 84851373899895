import React from "react";
import { matchPath } from "react-router";
import { withRouter } from "react-router-dom";
import { BreadcrumbGroup } from "@amzn/awsui-components-react";

// component for the breadcrumb links present at the top of the page
function Breadcrumbs({ location, history }) {
  const pathname = location.pathname;

  if (pathname === "/") {
    return null;
  }

  // all links will be children of the home link
  let items = [{ text: "DWaaS Pipeline Portal", href: "/home" }];

  if (pathname === "/notifications") {
    items = items.concat([{ text: "Notifications", href: "/notifications" }]);
  } else if (matchPath(pathname, { path: "/notifications/:id" })) {
    const match = matchPath(pathname, { path: "/notifications/:id" });
    items = items.concat([
      { text: "Notifications", href: "/notifications" },
      { text: `${match.params.id}`, href: `/notifications/${match.params.id}` },
    ]);
  } else if (pathname === "/files") {
    items = items.concat([{ text: "Files", href: "/files" }]);
  } else if (pathname === "/files/new") {
    items = items.concat([
      { text: "Files", href: "/files" },
      { text: "Create file", href: "/files/new" },
    ]);
  } else if (matchPath(pathname, { path: "/files/:id" })) {
    const match = matchPath(pathname, { path: "/files/:id" });
    items = items.concat([
      { text: "Files", href: "/files" },
      { text: `${match.params.id}`, href: `/files/${match.params.id}` },
    ]);
  }

  function onFollow(event) {
    event.preventDefault();
    history.push(event.detail.href);
  }

  return (
    <BreadcrumbGroup label="Breadcrumbs" items={items} onFollow={onFollow} />
  );
}

export default withRouter(Breadcrumbs);
