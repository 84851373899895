import React from "react";
import { withRouter } from "react-router-dom";

import Link from "../../components/Link";

function Empty(props) {
  return (
    <div className="awsui-util-t-c awsui-util-mv-s">
      <b>No files</b>
      <p className="awsui-util-mb-xs">No files to display.</p>
      <Link variant="normal" to="/files/new" text="Create file" />
    </div>
  );
}

export default withRouter(Empty);
