import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react";

import {
  fetchNotificationForUser,
  deleteNotification,
} from "../../models/Notification";

import Loader from "../../components/Loader";
import { getDateAndTime } from "../../utils";

function NotificationNotFound(props) {
  return (
    <div className="NotificationNotFound">
      <div className="message">
        <h3>Notification not found.</h3>
      </div>
    </div>
  );
}

function Notification(props) {
  const [isLoading, setIsLoading] = useState(false);

  async function removeNotification() {
    setIsLoading(true);
    await deleteNotification(props.notification.id);
    setIsLoading(false);
    props.history.push("/notifications");
  }

  return (
    <div>
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>{props.notification.subject}</h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              <Button
                type="button"
                loading={isLoading}
                disabled={isLoading}
                onClick={removeNotification}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
        <div className="awsui-util-spacing-v-s">
          <div>
            <div className="awsui-util-label">Source</div>
            <div>{props.notification.sender}</div>
          </div>
          <div>
            <div className="awsui-util-label">Message</div>
            <div>
              <pre>{props.notification.message}</pre>
            </div>
          </div>
          <div>
            <div className="awsui-util-label">Date</div>
            <div>{getDateAndTime(props.notification.createdAt)}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function NotificationDetails(props) {
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData(id) {
      const notification = await fetchNotificationForUser(
        userId,
        notificationId
      );
      setNotification(notification);
      setIsLoading(false);
      document.title = "DWaaS Pipeline Portal";
    }

    const userId = props.user.id;
    const notificationId = props.match.params.id;
    fetchData(userId, notificationId);
  }, [props.user.id, props.match.params.id]);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : notification ? (
        <Notification notification={notification} {...props} />
      ) : (
        <NotificationNotFound {...props} />
      )}
    </div>
  );
}

export default withRouter(NotificationDetails);
