import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Badge, SideNavigation } from "@amzn/awsui-components-react";

import {
  fetchNotificationsForUser,
  onCreateNotification,
  onDeleteNotification,
  Notification,
} from "../../models/Notification";

// component for the side navigation menu
function Navigation({ user, history, location }) {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    async function fetchData(userId) {
      const notifications = await fetchNotificationsForUser(userId);
      setNotifications(notifications);
    }

    fetchData(user.id);
  }, [user.id]);

  useEffect(() => {
    let subscription;
    function setupOnNotificationCreatedSubscription(userId) {
      subscription = onCreateNotification(userId, {
        next: (eventData) => {
          const notification = new Notification(
            eventData.value.data.onNotificationCreated
          );

          setNotifications((notifications) => {
            return [notification, ...notifications];
          });
        },
      });
    }

    if (user.id) {
      setupOnNotificationCreatedSubscription(user.id);
      return () => subscription.unsubscribe();
    }
  }, [user.id]);

  useEffect(() => {
    let subscription;
    function setupOnNotificationDeletedSubscription(userId) {
      subscription = onDeleteNotification(userId, {
        next: (eventData) => {
          const notification = new Notification(
            eventData.value.data.onNotificationDeleted
          );
          setNotifications((notifications) => {
            const index = notifications.findIndex(
              (item) => item.id === notification.id
            );

            return [
              ...notifications.slice(0, index),
              ...notifications.slice(index + 1),
            ];
          });
        },
      });
    }

    if (user.id) {
      setupOnNotificationDeletedSubscription(user.id);
      return () => subscription.unsubscribe();
    }
  }, [user.id]);

  const header = {
    href: "/files",
    text: "DWaaS Pipeline Portal",
  };

  const items = [
    { type: "link", text: "Getting started", href: "/home" },
    { type: "link", text: "Files", href: "/files" },
    { type: "divider" },
    {
      type: "link",
      text: "Notifications",
      href: "/notifications",
      info: (
        <Badge color={notifications.length > 0 ? "red" : "grey"}>
          {notifications.length}
        </Badge>
      ),
    },
  ];

  // the standard follow event triggers a page refresh. to avoid
  // this, we'll override the standard behavior and instead elect
  // to delegate the navigation to react router dom.
  function onFollow(event) {
    event.preventDefault();
    history.push(event.detail.href);
  }

  return (
    <SideNavigation
      activeHref={location.pathname}
      header={header}
      items={items}
      onFollow={onFollow}
    />
  );
}

export default withRouter(Navigation);
