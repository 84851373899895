import { API, graphqlOperation } from "aws-amplify";

import { deleteNotificationUserConnection } from "../graphql/mutations";

import {
  listNotificationsForUser,
  getNotificationForUser,
} from "./customQueries";

import {
  onCreateNotification as onCreate,
  onDeleteNotification as onDelete,
} from "./customSubscriptions";

export class Notification {
  constructor({ id, notification }) {
    this.id = id;
    this.sender = notification.sender;
    this.subject = notification.subject;
    this.message = notification.message;
    this.createdAt = notification.createdAt;
  }
}

export async function fetchNotificationsForUser(userId) {
  const response = await API.graphql(
    graphqlOperation(listNotificationsForUser, { id: userId })
  );
  return response.data.listNotificationUserConnections.items.map(
    (notification) => new Notification(notification)
  );
}

export async function fetchNotificationForUser(userId, notificationId) {
  const response = await API.graphql(
    graphqlOperation(getNotificationForUser, { userId, notificationId })
  );
  const notification = response.data.listNotificationUserConnections.items.pop();
  return new Notification(notification);
}

export async function deleteNotification(id) {
  const input = {
    id,
  };

  return await API.graphql(
    graphqlOperation(deleteNotificationUserConnection, { input })
  );
}

export function onCreateNotification(userId, callback) {
  return API.graphql(graphqlOperation(onCreate, { userId })).subscribe(
    callback
  );
}

export function onDeleteNotification(userId, callback) {
  return API.graphql(graphqlOperation(onDelete, { userId })).subscribe(
    callback
  );
}
