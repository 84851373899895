import React from "react";
import { Button } from "@amzn/awsui-components-react";

function NoMatch(props) {
  return (
    <div className="awsui-util-t-c awsui-util-mv-s">
      <b>No matches</b>
      <p className="awsui-util-mb-xs">We can't find a match.</p>
      <Button onClick={props.onClearFilter}>Clear filter</Button>
    </div>
  );
}

export default NoMatch;
