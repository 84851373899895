import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Home from "./pages/Home";
import Error from "./pages/Error";
import Notifications from "./pages/Notifications";
import NotificationDetails from "./pages/NotificationDetails";
import Files from "./pages/Files";
import NewFile from "./pages/NewFile";
import FileDetails from "./pages/FileDetails";
import NotFound from "./pages/NotFound";

function RedirectRoute({ path, to, ...rest }) {
  return (
    <Route {...rest} path={path}>
      <Redirect to={to} />
    </Route>
  );
}

function AppliedRoute({
  component: Component,
  props: componentProps,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => <Component {...props} {...componentProps} />}
    />
  );
}

function Router(props) {
  return (
    <Switch>
      <RedirectRoute exact path="/" to="/files" />
      <AppliedRoute exact path="/home" component={Home} props={props} />
      <AppliedRoute exact path="/files" component={Files} props={props} />
      <AppliedRoute exact path="/files/new" component={NewFile} props={props} />
      <AppliedRoute
        exact
        path="/files/:id"
        component={FileDetails}
        props={props}
      />
      <AppliedRoute
        exact
        path="/notifications"
        component={Notifications}
        props={props}
      />
      <AppliedRoute
        exact
        path="/notifications/:id"
        component={NotificationDetails}
        props={props}
      />
      <AppliedRoute exact path="/error" component={Error} props={props} />
      <Route component={NotFound} />
    </Switch>
  );
}

export default Router;
