import React from "react";
import { Link } from "react-router-dom";

import { getDateAndTime, toSentenceCase } from "../../utils";

export const COLUMN_DEFINITIONS = [
  {
    id: "name",
    header: () => "Name",
    cell: (item) => (
      <div>
        <Link to={`/files/${item.id}`}>{item.name}</Link>
      </div>
    ),
    allowLineWrap: false,
    minWidth: "180px",
  },
  {
    id: "folderName",
    header: () => "Folder",
    cell: (item) => item.folderName,
    allowLineWrap: false,
    minWidth: "100px",
  },
  {
    id: "status",
    header: () => "Status",
    cell: (item) => toSentenceCase(item.status),
    allowLineWrap: false,
    minWidth: "100px",
  },
  {
    id: "updatedAt",
    header: () => "Last modified",
    cell: (item) => getDateAndTime(item.updatedAt),
    allowLineWrap: false,
    minWidth: "100px",
  },
];

export const SORTABLE_COLUMNS = [
  { id: "name", field: "name" },
  { id: "folderName", field: "folderName" },
  { id: "status", field: "status" },
  { id: "updatedAt", field: "updatedAt" },
];

export const PROPERTY_FILTERING_OPTIONS = [
  {
    propertyLabel: "Folder",
    propertyKey: "folderName",
    groupValuesLabel: "Folder name values",
    values: [],
  },
  {
    propertyLabel: "Status",
    propertyKey: "status",
    groupValuesLabel: "Status values",
    values: [],
  },
];

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  placeholder: "Filter distributions by property or value",
  operationAndText: "and",
  operationNotAndText: "and not",
  operationOrText: "or",
  operationNotOrText: "or not",
  clearFiltersText: "Clear filters",
  groupPropertiesText: "Properties",
  groupValuesText: "Values",
  filteringEmpty: "No results found",
  filteringLabel: "Filter distributions",
  filteringStatusText: "Loading",
};

export function generateColumnLabel({ id, header }) {
  return function (sortState) {
    const columnIsSorted = sortState.sortingColumn === id;
    const ascending = !sortState.sortingDescending;

    return `${typeof header === "function" ? header() : header}, ${
      columnIsSorted
        ? `sorted ${ascending ? "ascending" : "descending"}`
        : "not sorted"
    }.`;
  };
}

export function addColumnSortLabels(columns, sortColumns) {
  return columns.map((column) => ({
    label: sortColumns.find((sortColumn) => sortColumn.id === column.id)
      ? generateColumnLabel(column)
      : undefined,
    ...column,
  }));
}

export function getHeaderCounterText(items, selectedItems) {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
}

export function getFilterCounterText(count) {
  return `${count} ${count === 1 ? "match" : "matches"}`;
}

export function removeTokenButtonLabel({ propertyLabel, value }) {
  return `Remove filtering token for ${propertyLabel} with value ${value}`;
}

export const selectionLabels = {
  itemSelectionLabel: (data, row) => `Select file ${row.id}`,
  allItemsSelectionLabel: () => "Select all files",
  selectionGroupLabel: "Files selection",
};

export const paginationLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};
