import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@amzn/awsui-components-react";

import Link from "../../components/Link";
import { getHeaderCounterText } from "./table-config";

function Header(props) {
  function onViewFileDetails() {
    props.history.push(`/files/${props.selectedItems.pop().id}`);
  }

  const counter = getHeaderCounterText(props.items, props.selectedItems);

  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <h2>
            {`${props.teamName.toUpperCase()} Files`}
            <span
              id="custom-actions-stripe-counter"
              className="awsui-util-header-counter"
            >
              {` ${counter}`}
            </span>
          </h2>
        </div>
        <div className="awsui-util-action-stripe-group">
          <Button icon="refresh" onClick={() => props.onRefresh()} />
          <Button
            disabled={props.selectedItems.length === 0}
            onClick={onViewFileDetails}
          >
            View details
          </Button>
          <Button
            disabled={props.selectedItems.length === 0}
            onClick={props.onDelete}
          >
            Delete
          </Button>
          <Link variant="primary" to="/files/new" text="Create file" />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
