import React from "react";
import { Link } from "react-router-dom";

import { getDateAndTime } from "../../utils";

export const COLUMN_DEFINITIONS = [
  {
    id: "subject",
    header: () => "Subject",
    cell: (item) => (
      <div>
        <Link to={`/notifications/${item.id}`}>{item.subject}</Link>
      </div>
    ),
    minWidth: "250px",
    allowLineWrap: false,
  },
  {
    id: "sender",
    header: () => "Source",
    cell: (item) => item.sender,
    allowLineWrap: false,
  },
  {
    id: "createdAt",
    header: () => "Date",
    cell: (item) => getDateAndTime(item.createdAt),
    minWidth: "150px",
    allowLineWrap: false,
  },
];

export const SORTABLE_COLUMNS = [
  { id: "subject", field: "subject" },
  { id: "sender", field: "sender" },
  { id: "createdAt", field: "createdAt" },
];

export function generateColumnLabel({ id, header }) {
  return function (sortState) {
    const columnIsSorted = sortState.sortingColumn === id;
    const ascending = !sortState.sortingDescending;

    return `${typeof header === "function" ? header() : header}, ${
      columnIsSorted
        ? `sorted ${ascending ? "ascending" : "descending"}`
        : "not sorted"
    }.`;
  };
}

export function addColumnSortLabels(columns, sortColumns) {
  return columns.map((column) => ({
    label: sortColumns.find((sortColumn) => sortColumn.id === column.id)
      ? generateColumnLabel(column)
      : undefined,
    ...column,
  }));
}

export function getHeaderCounterText(items, selectedItems) {
  return selectedItems && selectedItems.length > 0
    ? `(${selectedItems.length}/${items.length})`
    : `(${items.length})`;
}

export function getFilterCounterText(count) {
  return `${count} ${count === 1 ? "match" : "matches"}`;
}

export const notificationSelectionLabels = {
  itemSelectionLabel: (data, row) => `select ${row.id}`,
  allItemsSelectionLabel: () => "select all",
  selectionGroupLabel: "Notification selection",
};

export const paginationLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};
