import React from "react";
import { Button, Modal } from "@amzn/awsui-components-react";

// modal to challenge the user to confirm their file
// delete action.
function ConfirmDeleteModal({
  visible,
  isLoading,
  fileName,
  onConfirm,
  onDismiss,
}) {
  const footer = (
    <span className="awsui-util-f-r">
      <Button
        type="button"
        variant="link"
        disabled={isLoading}
        onClick={onDismiss}
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="primary"
        loading={isLoading}
        disabled={isLoading}
        onClick={onConfirm}
      >
        Delete
      </Button>
    </span>
  );

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      header="Confirm delete"
      footer={footer}
    >
      <div>Are you sure you want to delete {fileName}?</div>
    </Modal>
  );
}

export default ConfirmDeleteModal;
