import React from "react";
import { Button, Modal } from "@amzn/awsui-components-react";

// modal to challenge the user to confirm their file
// overwrite action
function ConfirmOverwriteModal({
  visible,
  isLoading,
  fileName,
  onConfirm,
  onDismiss,
}) {
  const footer = (
    <span className="awsui-util-f-r">
      <Button
        type="button"
        variant="link"
        disabled={isLoading}
        onClick={onDismiss}
      >
        Cancel
      </Button>
      <Button
        type="button"
        variant="primary"
        loading={isLoading}
        disabled={isLoading}
        onClick={onConfirm}
      >
        Overwrite
      </Button>
    </span>
  );

  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss}
      header="Confirm overwrite"
      footer={footer}
    >
      <div>
        A file by that name already exists in this location. Do you want to
        overwrite it?
        <div className="awsui-util-mt-l">
          <div className="awsui-util-label">Name</div>
          <div>{fileName}</div>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmOverwriteModal;
