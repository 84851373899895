import { API, graphqlOperation } from "aws-amplify";

import { getTeam } from "../graphql/queries";
import { getTeamWithFoldersAndFiles } from "./customQueries";

import { File } from "./File";

export class Team {
  constructor(id, name, folders) {
    this.id = id;
    this.name = name;
    this._folders = folders;
  }

  get folders() {
    return this._folders.items.sort((x, y) => y.name < x.name);
  }

  get files() {
    return this._folders.items
      .map((folder) => {
        const files = folder.files.items.map((file) => {
          const folderId = folder.id;
          const folderName = folder.name;

          return new File(
            file.id,
            folderId,
            folderName,
            file.name,
            file.location,
            file.status,
            file.createdAt,
            file.updatedAt,
            file.contributors
          );
        });

        return {
          ...folder,
          files,
        };
      })
      .reduce((allFiles, folder) => allFiles.concat(folder.files), [])
      .sort((x, y) => new Date(y.updatedAt) < new Date(x.updatedAt));
  }

  hasFolder(folderId) {
    return this.folders.findIndex((folder) => folder.id === folderId) >= 0;
  }

  getFile(id) {
    return this.files.find((file) => file.id === id);
  }

  addFile(file) {
    const index = this._folders.items.findIndex(
      (folder) => folder.id === file.folderId
    );

    this._folders.items[index].files.items.push(file);
  }

  deleteFile(file) {
    const folderIndex = this._folders.items.findIndex(
      (folder) => folder.id === file.folderId
    );

    const fileIndex = this._folders.items[folderIndex].files.items.findIndex(
      (folderFile) => folderFile.id === file.id
    );

    this._folders.items[folderIndex].files.items = [
      ...this._folders.items[folderIndex].files.items.slice(0, fileIndex),
      ...this._folders.items[folderIndex].files.items.slice(fileIndex + 1),
    ];
  }
}

export async function fetchTeamById(id) {
  const response = await API.graphql(graphqlOperation(getTeam, { id }));
  const team = response.data.getTeam;

  return team && new Team(team.id, team.name, team.folders);
}

export async function fetchTeamWithFolderAndFiles(teamId) {
  const response = await API.graphql(
    graphqlOperation(getTeamWithFoldersAndFiles, { id: teamId })
  );
  const team = response.data.listTeams.items.pop();

  return new Team(team.id, team.name, team.folders);
}
