/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      users {
        items {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        nextToken
      }
      folders {
        items {
          id
          teamId
          name
        }
        nextToken
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        users {
          nextToken
        }
        folders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      sub
      teamId
      username
      first_name
      last_name
      email
      files {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
      notifications {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const userByUsername = /* GraphQL */ `
  query UserByUsername(
    $username: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByUsername(
      username: $username
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFolder = /* GraphQL */ `
  query GetFolder($id: ID!) {
    getFolder(id: $id) {
      id
      teamId
      name
      files {
        items {
          id
          folderId
          name
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listFolders = /* GraphQL */ `
  query ListFolders(
    $filter: ModelFolderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamId
        name
        files {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      folderId
      name
      location {
        region
        bucket
        key
      }
      status
      createdAt
      updatedAt
      contributors {
        items {
          id
          userId
          fileId
        }
        nextToken
      }
    }
  }
`;
export const fileByName = /* GraphQL */ `
  query FileByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fileByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sender
        subject
        message
        createdAt
        recipients {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      sender
      subject
      message
      createdAt
      recipients {
        items {
          id
          userId
          notificationId
        }
        nextToken
      }
    }
  }
`;
export const getFileUserConnection = /* GraphQL */ `
  query GetFileUserConnection($id: ID!) {
    getFileUserConnection(id: $id) {
      id
      userId
      fileId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      file {
        id
        folderId
        name
        location {
          region
          bucket
          key
        }
        status
        createdAt
        updatedAt
        contributors {
          nextToken
        }
      }
    }
  }
`;
export const listFileUserConnections = /* GraphQL */ `
  query ListFileUserConnections(
    $filter: ModelFileUserConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFileUserConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        fileId
        user {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        file {
          id
          folderId
          name
          status
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getNotificationUserConnection = /* GraphQL */ `
  query GetNotificationUserConnection($id: ID!) {
    getNotificationUserConnection(id: $id) {
      id
      userId
      notificationId
      user {
        id
        sub
        teamId
        username
        first_name
        last_name
        email
        files {
          nextToken
        }
        notifications {
          nextToken
        }
      }
      notification {
        id
        sender
        subject
        message
        createdAt
        recipients {
          nextToken
        }
      }
    }
  }
`;
export const listNotificationUserConnections = /* GraphQL */ `
  query ListNotificationUserConnections(
    $filter: ModelNotificationUserConnectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotificationUserConnections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationId
        user {
          id
          sub
          teamId
          username
          first_name
          last_name
          email
        }
        notification {
          id
          sender
          subject
          message
          createdAt
        }
      }
      nextToken
    }
  }
`;
