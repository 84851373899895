import React, { useEffect, useState } from "react";
import { Auth, Hub } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { AppLayout, Flashbar } from "@amzn/awsui-components-react";

import { fetchUserByUsername } from "./models/User";

import Loader from "./components/Loader";
import Navigation from "./components/Navigation";
import Breadcrumbs from "./components/Breadcrumbs";
import SignInError from "./components/SignInError";
import Router from "./Router";

// base component for the dwaas pipeline portal. all views
// are children of this component.
function App(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(undefined);
  const [notifications, setNotifications] = useState([]);
  const [hasError, setHasError] = useState(false);

  // when the user is redirected back to the portal after
  // successfully signing in via midway, the auth events
  // "cognitoHostedUI" and "signIn" will trigger with a
  // payload. we'll use that to fetch the user's information
  Hub.listen("auth", async (data) => {
    console.log("hub event: ", data.payload.event);
    switch (data.payload.event) {
      case "cognitoHostedUI":
      case "signIn":
        const username = data.payload.data.username.split("_").pop(); // federate_username => username
        const user = await fetchUserByUsername(username);
        setUser(user);
        setIsLoading(false);
        break;
      case "signIn_failure":
        console.error(data);
        setHasError(true);
        setIsLoading(false);
        break;
      default:
        break;
    }
  });

  // on page load, check to see if the user already has
  // a session present. if so, fetch their account 
  // information. if not, redirect them to the midway
  // auth challenge (saved as FederateOIDC)
  useEffect(() => {
    async function checkForUserSession() {
      const hasSession = await userHasSession();
      if (hasSession) {
        await fetchUserInfo();
      } else {
        // no sesion was found. redirect user to federate login.
        await Auth.federatedSignIn({ provider: "FederateOIDC" });
      }
    }

    checkForUserSession();
  }, []);

  // indicates whether the user already has a session saved
  function userHasSession() {
    return new Promise(async (resolve) => {
      // if we already have credentials, return and proceed
      const creds = await Auth.currentCredentials();
      if (creds && creds.authenticated) {
        return resolve(creds.authenticated);
      }

      // Otherwise, we'll retry but with an added delay. We need to allow amplify
      // enough time to consume the code + token provided from the federate
      // redirect and stash them to local storage. Without it, we risk entering
      // an endless loop in which the client attempts to sign in to federate
      // over and over.
      setTimeout(async () => {
        const creds = await Auth.currentCredentials();
        resolve(creds && creds.authenticated);
      }, 850);
    });
  }

  async function fetchUserInfo() {
    const response = await Auth.currentAuthenticatedUser();
    const username = response.username.split("_").pop(); // federateOIDC_username => username
    const user = await fetchUserByUsername(username);
    setUser(user);
    setIsLoading(false);
  }

  // Shows a banner message at the top of the page.
  function triggerNotification(message, type = "success") {
    const notification = {
      type: type,
      content: message,
      dismissible: true,
    };

    setNotifications([notification]);
    setTimeout(() => setNotifications([]), 10000);
  }

  // Adjusts the app layout according to the desired page.
  // The AppLayout component accepts different inputs and
  // will adjust its layout accordingly. The 'form' value,
  // for example, will automatically hide the side
  // navigation window.
  function getContentType() {
    const pathname = props.location.pathname;

    if (pathname === "/home") {
      return "no-paddings";
    }

    if (pathname === "/files" || pathname === "/notifications") {
      return "table";
    }

    if (pathname === "/files/new") {
      return "form";
    }

    return "default";
  }

  const contentType = getContentType();

  // We do not want to display breadcrumb links on the
  // "getting started" or the error page.
  const breadcrumbs =
    props.location.pathname === "/home" ||
    props.location.pathname === "/error" ? null : (
      <Breadcrumbs />
    );

  return isLoading ? (
    <Loader />
  ) : hasError ? (
    <SignInError />
  ) : (
    <div className="App">
      <AppLayout
        contentType={contentType}
        content={
          <Router user={user} triggerNotification={triggerNotification} />
        }
        navigation={<Navigation user={user} />}
        breadcrumbs={breadcrumbs}
        notifications={
          <Flashbar
            items={notifications}
            onDimiss={(ev) => console.log("dismiss", ev)}
          />
        }
        toolsHide={true}
      />
    </div>
  );
}

export default withRouter(App);
